import React from 'react';
import { shape } from 'prop-types';

import { createBlok } from 'base/utils/bloks';

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return StoryblokEntry.prepareStory(props);
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story);
    const client = Object.assign({}, props.pageContext.client);

    story.content = JSON.parse(story.content);

    return { story, client };
  }

  constructor(props) {
    super(props);

    this.state = StoryblokEntry.prepareStory(props);
  }

  render() {
    const content = this.state.story.content;
    const client = this.state.client;

    return createBlok(content.component, {
      key: content._uid,
      data: content,
      client,
      storyblokMode:
        process.env.NODE_ENV === 'production' ? 'published' : 'draft',
      ...this.props,
    });
  }
}

StoryblokEntry.propTypes = {
  pageContext: shape(),
};

export default StoryblokEntry;
